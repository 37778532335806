import React from "react";
import {useLanguageContext} from "./Contexts/LanguageContext";

export default function LanguageHandler() {

    const { changeLanguage} = useLanguageContext();
    return (
        <div className='langChange'>
            <button onClick={() => changeLanguage('ro')} className="btn">RO</button>
                <div className="line"></div>
            <button onClick={() => changeLanguage('ru')} className="btn">RU</button>
        </div>

    );
}
