import React from "react";
import classes from "./Contact.module.css"
import useTranslation from "../../customHooks/translations";

function Contact() {
    const translation = useTranslation();
    return (
        <div className={classes.contactMain}>
          <h1 className="page-title">{translation.contactTitle}</h1>
            <div className={classes.mainContent}>
                <div className={classes.logInfo}>
                    <img src="image/home/logo.svg" alt="logo" className={classes.log}/>
                    <div className={classes.contactInfo}>
                        Miron Costin 19/1 A <br/>
                        Chișinău,
                      <a href="mailto:office@usgsrl.md"> office@usgsrl.md</a>
                      <div>
                        <span>Tel: </span>
                        <a href="tel:079508070">079508070</a>
                        <span>, </span>
                        <a href="tel:079507010">079507010</a>
                      </div>
                     </div>
                </div>
              <div className={classes.iframeWrap}>
                <div className={classes.iframeContainer}>
                  <iframe
                    title="myFrame"
                    className={classes.map}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.48775584149!2d28.868237615611363!3d47.0502815791517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97cfa939bf08f%3A0x9ff9593b7682c4ac!2sStrada%20Miron%20Costin%2019%2C%20Chi%C8%99in%C4%83u!5e0!3m2!1sru!2s!4v1643540880086!5m2!1sru!2s"
                    allowFullScreen="" loading="lazy">
                  </iframe>
                </div>
              </div>
            </div>
        </div>
    );
}

export default Contact;
