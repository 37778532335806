import React, {useEffect} from "react";
import './App.css';
import Header from "./Components/Pages/Header/Header";
import Footer from "./Components/Pages/Footer/Footer";
import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./Components/Pages/Home/Home";
import About from "./Components/Pages/About/About";
import Service from "./Components/Pages/Service/Service";
import Contact from "./Components/Pages/Contact/Contact";
import ReactGa from 'react-ga';
import * as ReactGA from "react-ga";
import { YMInitializer } from 'react-yandex-metrika';

function App() {
    const loc = useLocation();
    console.log(loc.pathname);
    useEffect(() => {
        ReactGa.initialize('G-BN22K24W83');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="App">
            <YMInitializer accounts={[87397508]} options={{webvisor: true}}/>
            <div className="main">
                <Header/>
                <Routes>
                    <Route exact path="/"
                           element={<Home/>}/>
                    <Route path="/service" element={<Service/>}/>
                    <Route path="/about"
                           element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </div>
            <Footer/>
        </div>
    );
}


export default App;
