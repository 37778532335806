import React, {useState} from "react";
import "./Service.css";
import useTranslation from "../../customHooks/translations";
import ServiceItem from "./ServiceItem";

const Service = () => {
  const translation = useTranslation();

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      <div className="service">
        <h1 className="page-title">{translation.serviceTitle}</h1>
        <div className="service-items">
          {
            Object.entries(translation.service).map(([key, item]) => (
              <ServiceItem
                key={key}
                classes={"service-item service-" + key}
                title={item.title}
                main={item.mainInfo}
                more={item.addInfo}
                otherServices={item.otherServices}
              />
            ))
          }
        </div>
        <div className="footerBlock">
          <img className="hide-on-mobile" src="image/home/logo.svg" alt="logo"/>
          <p className="copyRight">@UNIVERSAL SECURITY GROUP</p>
        </div>
      </div>
    </div>
  );
}

export default Service;
