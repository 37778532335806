export default {
    homePara: "Pagina centrala",
    serviceTitle:"Serviciile noastre",
    aboutTitle: "Despre noi",
    aboutText1: "Organizația de Pază „Universal Security Group” S.R.L. a fost constituită la 27 octombrie 2016 și\n" +
        "                        activează în baza Legii nr.283/2003 privind activitatea particulară de detectiv și de pază,\n" +
        "                        Autorizației de funcționare Nr. 0119 din 09 septembrie 2020, eliberată de Inspectoratul Național\n" +
        "                        de Securitate Publică a IGP al MAI.",
    aboutText2: "Compania prestează întreaga gamă de servicii necesare clienților, la costuri moderate şi cu un\n" +
        "                        coraport preț-calitate competitiv pe piață. O.P. ”Universal Security Group” S.R.L. este condusă\n" +
        "                        de o echipă managerială, care dispune de o valoroasă experiență profesională. Această experiență\n" +
        "                        permite Companiei noastre să furnizeze soluţii orientate spre rezolvarea eficientă a cerințelor\n" +
        "                        înaintate de beneficiari şi să presteze servicii de pază şi securitate calitative, în permanență\n" +
        "                        actualizând procedeele de lucru și implementând în activitate tehnologii avansate.\n" +
        "                        Organizaţia de Pază “Universal Securiry Group” S.R.L. deţine la moment un portofoliu important\n" +
        "                        de servicii prestate pentru toate domeniile de activitate şi intenţionăm să devenim în\n" +
        "                        perspectivă o Companie ce utilizează toate resursele disponibile pentru realizarea celor mai\n" +
        "                        importante obiective, respectiv satisfacerea necesitaţilor şi intereselor clientilor noştri.\n" +
        "                        Ne-am creat politici, strategii, obiective şi misiuni specifice în domeniu, pe care le adaptăm\n" +
        "                        permanent evoluţiilor de pe piaţa Republicii Moldova.\n" +
        "                        Organizaţia de Pază “Universal Security Group” S.R.L. menţine permanent legătură cu instituțiile\n" +
        "                        competente din domeniile de activitate, astfel încât orice acţiune să fie realizată în\n" +
        "                        conformitate cu prevederile legale şi să asigure soluţionarea rapidă a situaţiilor ce pun în\n" +
        "                        pericol viaţa, sănătatea şi integritatea persoanelor, a bunurilor şi valorilor materiale.",
    service: {
        securitate: {
            title: "Servicii de păstrare a bunurilor și obiectelor de valoare în casete de siguranță",
            description1: "O.P. ”Universal Security Group” S.R.L. prestează persoanelor juridice și fizice\n" +
                "                                    servicii de păstrare a bunurilor și obiectelor de valoare în casete de siguranță.\n" +
                "                                    Casetele de siguranță sunt amplasate într-o încăpere separată,",
            description2: "numită ”Tezaur”,\n" +
                "                                    dotat cu echipament tehnic de ultima generație și racordat la sistemul centralizat\n" +
                "                                    de pază, fiind o soluție perfectă pentru păstrarea în siguranță a bunurilor și\n" +
                "                                    obiectelor de valoare la un preț avantajos.\n" +
                "\n" +
                "                                    Casetele de siguranță sunt accesibile tuturor persoanelor juridice și fizice,\n" +
                "                                    rezidenți și nerezidenți ai Republicii Moldova.\n" +
                "                                    Tezaurul cu casete de siguranță este amplasat în mun.Chișinău\n" +
                "                                    ",
            mainInfo:"Casetele individuale de păstrare în locație de maximă siguranță – soluție pentru moldovenii care vor să-și protejeze valorile!\n",
            addInfo:"\nCel mai sigur loc din Chișinău pentru păstrarea bunurilor de valoare!\n" +
                "\nPăstrarea bunurilor de valoare acasă reprezintă un risc. Or, cine din noi nu a deteriorat un document important (testamentul, certificate de proprietate, titluri de acțiuni ) sau nu a pierdut, prin casă, un inel valoros sau o monedă de colecție? Pentru a păstra în siguranță astfel de bunuri de valoare, în toată lumea, oamenii apelează la închirierea safeurilor în locații specializate, de maximă siguranță.\n" +
                "\nÎn R. Moldova, acest gen de servicii este prestat de unele bănci, și deși solicitări există, acestea nu doresc să extindă acest gen de servicii. Motivul e simplu, băncile au înțeles că nu pot asigura securitatea la maxim în aceste locații, dovada fiind și jaful din 2019, când 80 de deponenți în safeurile unei bănci, au rămas fără valorile lor. Totuși, în toată lumea, casetele individuale în altă locație decât acasă, sunt o soluție extrem de solicitată. În aceste condiții, o companie din R. Moldova a deschis prima locație cu casete individuale, care pot fi închiriate de cei care își vor bunurile de valoare în siguranță.\n" +
                "\nCum este protejată locația?\n" +
                "\nÎncăperea sau cum mai este numit, Tezaurul cu casete individuale de siguranță este special amenajată cu coloane metalice, compartimentate cu casete individuale de siguranță pentru păstrarea și asigurarea integrității bunurilor.\n" +
                "\nTezaurul este dotat cu echipament special care include sistemul de supraveghere video, sistemul de alarmă, sistemul control acces, antiefracție şi de prevenire a incendiului, măsuri sporite de protecție și pază reieșind din posibile riscuri. Acesta este monitorizat, prin intermediul sistemelor moderne de pază, în regim non-stop, de către 2 (doi) operatori ai Dispeceratului centralizat de pază, care se află în același sediu (în nemijlocita apropiere de Tezaur).\n" +
                "\nÎn caz de încercare de pătrundere neautorizată sau prelucrare a sistemelor antiefracție la obiectiv v-or sosi echipele mobile, atât ale Prestatorului cât și ale companiilor de profil cu care Prestatorul a semnat contracte speciale de deservire (cea ce permite asigurarea unui nivel sporit de intervenție – timp redus (echipa amplasată în adiacent va sosi la obiectiv în timp de 2-3 minute, comparativ cu 5-7 min. la obiectivele bancare), număr sporit de agenți de intervenție (6 în comparație cu 2 la bănci), posibilități mai eficiente de dirijare cu agenții de intervenții (deoarece secvențele de pe camerele video permit a orienta personalul spre care acțiuni și în ce zonă trebuie întreprinse).\n" +
                "\nCe tipuri de casete individuale sunt disponibile?\n" +
                "\nMărimile sunt variate, de la foarte mici la mari. Nemijlocit, în dependență de ce vreți să puneți la păstrare puteți opta pentru mărimi de: 25x44x5,0, 25x44x7,0, 25x44x12, 25x44x16, 25x44x30.\n" +
                "\nCu ce sisteme de protecție sunt dótate casetele invidiaule?\n" +
                "\nCasete individuale de siguranță – reprezintă casete numerotate, de diferite dimensiuni, destinate păstrării bunurilor, prevăzute cu lacăte și chei distincte. Fiecare casetă individuală de siguranță este dotată cu lacăt cu două chei unice de acces. Acțiunile de manipulare a casetei individuale de siguranță de către Beneficiar (de retragere sau depunere a bunurilor din acestea) au loc în încăperea pentru operațiuni din cadrul Tezaurului, care reprezintă o cameră de acces exclusiv pentru Beneficiar, înzestrată cu ușă sigură, fără geamuri și fără supraveghere video, dotată cu o masă și rechizite de birou.\n" +
                "\nCe bunuri pot fi depuse în casete?\n" +
                "\nPot fi depuse documente, valori mobiliare materializate, obiecte de preţ, obiecte de valoare personală, precum şi oricare alte bunuri. Prestatorul nu duce evidenţa bunurilor depuse de către Beneficiar în caseta individuală de siguranță şi garantează inviolabilitatea casetei individuale de siguranță prin excluderea accesului altor persoane decât Beneficiarul sau persoanele împuternicite de el. Conținutul casetelor individuale de siguranță este cunoscut doar de către Beneficiar.\n" +
                "\nAsigurare de 10 milioane de lei\n" +
                "\nȘi ca să fiți siguri că bunurile dvs., se află în cel mai sigur loc din Chișinău, compania a asigurat Tezaurul contra sumei de 10 000 000 lei. Astfel, USG își asumă răspunderea față de beneficiari pentru neexecutarea sau executarea necorespunzătoare a obligațiunilor contractuale.\n" +
                "\nNu riscăm. Garantăm!"
        },
        alarm: {
            otherServices: "\nAlte servicii\n",
            title: "Serviciul de proiectare, instalare și întreținerea sistemelor de alarma",
            description1: "O.P. ”Universal Security Group” S.R.L. oferă servicii de proiectare, instalare și întreținere a sistemelor de alarma.\n" +
                "           Proiectarea unui sistem de securitate presupune stabilirea unei configurații complexe de echipamente: centrale de alarma, echipament ",
            description2: "de detecție și semnalizare, camere video, înregistrare digitale, sistemul de control al accesului, cât și adoptarea măsurilor de securitate a obiectivelor, bunurilor și valorilor prevăzute de legislație, transpuse în planul de pază și proiectele sistemelor de securitate mecanică și electronică.\n" +
                "           Contactați-ne, iar specialiștii noștri în proiectare, instalare și întreținere a sistemelor de alarma vă vor oferi cele mai bune soluții.\n" +
                "",
            mainInfo:"O.P. ”Universal Security Group” S.R.L. oferă servicii de proiectare, instalare și întreținere a sistemelor de alarma.\n" +
                "           Proiectarea unui sistem de securitate presupune stabilirea unei configurații complexe de echipamente.",
            addInfo:" Acestea sunt: centrale de alarma, echipament de detecție și semnalizare, camere video, înregistrare digitala, sistem de control al accesului, cât și adoptarea măsurilor de securitate a obiectivelor, bunurilor și valorilor prevăzute de legislație, transpuse în planul de pază și proiectele sistemelor de securitate mecanică și electronică.\n" +
                "           Contactați-ne, iar specialiștii noștri în proiectare, instalare și întreținere a sistemelor de alarma vă vor oferi cele mai bune soluții.\n" +
                "\n"
        },
        pazaFizica: {
            title: "Paza Fizică",
            description1: "  O.P “Universal Security Group” S.R.L oferă agenților economici și persoanelor fizice\n" +
                "                                    servicii de pază fizică la cele mai înalte standarde existente pe piață.",
            description2: "Serviciile de pază fizică prestate de către Compania noastră sunt adaptate fiecărui segment de activitate și fiecărui Client în parte. Prestarea serviciilor de pază fizică la întreprinderi de producere, comerciale, oficii, locații de agrement, complexe rezidențiale, instituții de învățământ și culturale, case și apartamente de locuit se efectuează în regim de activitate 24/24, sau în regim de zi conform programului prestabilit. ",
            mainInfo:"         O.P “Universal Security Group” S.R.L oferă agenților economici și persoanelor fizice servicii de pază fizică la cele mai înalte standarde existente pe piață. Serviciile de pază fizică prestate de către Compania noastră sunt adaptate fiecărui segment de activitate și fiecărui Client în parte.",
            addInfo: "Prestarea serviciilor de pază fizică la întreprinderi de producere, comerciale, oficii, locații de agrement, complexe rezidențiale, instituții de învățământ și culturale, case și apartamente de locuit se efectuează în regim de activitate 24/24, sau în regim de zi conform programului prestabilit."
        },
        transport: {
            title: "Serviciul de transportare",
            description1: "Serviciile prestate de O.P. “Universal Security Group” S.R.L privind transportul,\n" +
                "                                    paza și însoțirea încărcăturilor/valorilor importante, a bunurilor personale, cât și\n" +
                "                                    asigurarea integrității acestora reprezintă un serviciu pe care îl asigurăm\n" +
                "                                    clienților noștri, respectând prevederile legislației în vigoare.",
            description2:" Personalul însoțitor este constituit din agenți antrenați pentru astfel de\n" +
                "                                    activități, pregătiți să realizeze tehnici moderne de asigurare a secuității la\n" +
                "                                    însoțirea încărcăturilor/valorilor importante, tehnici de conducere defensivă și\n" +
                "                                    evitarea accidentele rutiere.\n" +
                "                                    Astfel O.P “Universal Security Group” S.R.L asigură integral întreg portofoliul de\n" +
                "                                    servicii privind transportarea, paza și însoțirea încărcăturilor/valorilor\n" +
                "                                    importante pe întreg teritoriul țării.",
            mainInfo:" Serviciile prestate de O.P. “Universal Security Group” S.R.L privind transportul, paza și însoțirea încărcăturilor/valorilor importante, a bunurilor personale, cât și asigurarea integrității acestora reprezintă un serviciu pe care îl asigurăm clienților noștri, respectând prevederile legislației în vigoare.",
            addInfo: "Personalul însoțitor este constituit din agenți antrenați pentru astfel de activități,  pregătiți să realizeze tehnici moderne de asigurare a secuității la însoțirea încărcăturilor/valorilor importante, tehnici de conducere defensivă și evitarea accidentele rutiere.\n" +
                "        Astfel O.P  “Universal Security Group” S.R.L asigură integral întreg portofoliul de servicii privind transportarea, paza și însoțirea încărcăturilor/valorilor importante pe întreg teritoriul țării.\n"
        },
        corp:{
            title:"Paza de corp",
            description1: "        O.P. \"Universal Security Group\" SRL oferă servicii privind executarea gărzii de corp la cele mai înalte standarde. Serviciile oferite de Compania noastră aduc siguranța și protecția dorită. La furnizarea servicilor executării gărzii de corp, cele mai bune măsuri de protecție sunt adoptate numai după efectuarea unui studiu al riscurilor la care sunt supuse persoanele care trebuie protejate, studiu realizat de specialiști în domeniu în cadrul Companiei noastre",
            description2: " Personalul\n" +
                "                                    angajat în serviciul executării garzii de corp au vârste cuprinse între 20 și 50 de\n" +
                "                                    ani, este\n" +
                "                                    profesionist, dispun de experiență acumulată în structurile de protecție, pază și\n" +
                "                                    securitate ale\n" +
                "                                    statului, posedă capacități și calități intelectuale și morale ridicate, abilități\n" +
                "                                    și percepții\n" +
                "                                    practice la manipularea și aplicarea armei de foc și forței fizice.",
            mainInfo:"O.P. \"Universal Security Group\" SRL oferă servicii privind executarea gărzii de corp la cele mai înalte standarde. Serviciile oferite de Compania noastră aduc siguranța și protecția dorită. La furnizarea servicilor executării gărzii de corp, cele mai bune măsuri de protecție sunt adoptate numai după efectuarea unui studiu al riscurilor la care sunt supuse persoanele care trebuie protejate, studiu realizat de specialiști în domeniu în cadrul Companiei noastre. ",
            addInfo: " Personalul angajat în serviciul executării garzii de corp au vârste cuprinse între 20 și 50 de ani, este profesionist, dispun de experiență acumulată în structurile de protecție, pază și securitate ale statului, posedă capacități și calități intelectuale și morale ridicate, abilități și percepții practice la manipularea și aplicarea armei de foc și forței fizice.    Angajații Companiei noastre își îmbunătățesc permanent măiestria profesională, sunt testați periodic, participând la diverse exerciții și situații de caz în condiții egale cu cele ale situațiilor reale.\n" +
                "       Apelând la serviciile noastre, veți descoperi o echipă de profesioniști care vă vor oferi, în orice moment, cea mai fiabilă soluție în problemele legate de securitatea dumneavoastră și a membrilor familiei dumneavoastră.\n"
        }
    },
    navBar:{
        home:"Acasă",
        service: "Servicii",
        about:"Despre noi",
        contact:"Contacte"
    },
    contactTitle:"Contacte",
    seeMore: "Mai multe",
    seeLess: "Mai puține",
};
