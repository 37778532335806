import React, {useState} from 'react';
import useTranslation from "../../../customHooks/translations";
import './service-item.css';

const ServiceItem = ({key, classes, title, main, more, otherServices }) => {
  const translation = useTranslation();
  const [showMore, setShowMore] = useState(false);
  return (
    <div key={key} className={classes}>
        {otherServices ?
            <h2 className={"page-title"}>{otherServices}</h2> : <></>
        }
      <h2 className={"service-title service-" + key}>{title}</h2>
      <div className="service-info">{main}</div>
      {showMore && <div className="service-more-info">{more}</div>}
      <button className={showMore ? "service-btn is-up" : "service-btn is-down"} onClick={() => setShowMore(!showMore)}>
        {showMore ?
          translation.seeLess : translation.seeMore
        }
      </button>
    </div>
  )
};

export default ServiceItem;
