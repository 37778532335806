import React from "react";
import classes from "./Navbar.module.css"
import {NavLink} from "react-router-dom";
import useTranslation from "../../customHooks/translations";




function Navbar(props) {
    const setActive = ({isActive}) => (isActive ? classes.active : classes.inActive);
    const translation = useTranslation();

    return (
        <div className={classes.main}>
            <nav className={classes.Navbar}>
                <div className={classes.navItem}>
                    <NavLink to="/" className={setActive}> {translation.navBar.home}</NavLink>
                </div>
                <div className={`${classes.navItem} ${classes.active}`}>
                    <NavLink to="/service" className={setActive}> {translation.navBar.service}</NavLink>
                </div>
                <div className={classes.navItem}>
                    <NavLink to="/about" className={setActive}> {translation.navBar.about} </NavLink>
                </div>
                <div className={`${classes.navItem} ${classes.contacte}`} >
                    <NavLink to="/contact" className={setActive}> {translation.navBar.contact}</NavLink>
                </div>
            </nav>
        </div>


    );
}

export default Navbar;
