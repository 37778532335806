export default {
    homePara: "Главная страница",
    serviceTitle:"Наши Услуги",
    aboutTitle: "О нас",
    aboutText1: " Охранная организация \"Universal Security Group\" SRL была создана 27 октября 2016 года и действует в соответствии с Законом № 283/2003 о частной деятельности детективов и охранников, Разрешение на деятельность №.0119 от 09.09.2020, выданный Национальной инспекцией общественной безопасности ГИП.",
    aboutText2: "Компания предоставляет полный спектр услуг, необходимых клиентам, по умеренным ценам и с конкурентоспособным на рынке соотношением цена-качество. O.P. \"Universal Security Group\" SRL возглавляет управленческая команда, имеющая ценный профессиональный опыт. Этот опыт позволяет нашей Компании предоставлять решения, направленные на эффективное решение требований, предъявляемых бенефициарами, и предоставлять качественные услуги по охране и безопасности, постоянно обновляя рабочие процедуры и внедряя в деятельность передовые технологии.\n" +
        "Охранная организация \"Universal Securiry Group\" SRL в настоящее время имеет важный портфель услуг для всех сфер деятельности и мы намерены стать в перспективе компанией, которая использует все имеющиеся ресурсы для достижения самых важных целей, а именно удовлетворения потребностей и интересов наших клиенты .\n" +
        "Мы создали конкретные политики, стратегии, цели и миссии в этой области, которые мы постоянно адаптируем к изменениям на рынке Республики Молдова.\n" +
        "Охранная организация \"Universal Security Group\" SRL поддерживает постоянный контакт с компетентными учреждениями в сферах деятельности, чтобы любые действия проводились в соответствии с правовыми нормами и обеспечивали быстрое разрешение ситуаций, угрожающих жизни, здоровью и неприкосновенности лиц, материальных благ и ценностей .\n",
    service: {
        securitate: {
            title: "Услуги по хранению ценностей в индивидуальных ячейках",
            description1: "O.P.  «Universal Security Group» SRL предлагает юридическим и физическим лицам услуги по хранению ценностей в индивидуальных ячейках. Надежные ячейки от O.P. \"Universal Security Group\" SRL – это идеальное решение,  ",
            description2: "гарантирующее сохранность ценностей при хороших условиях, по отличной цене. Для обеспечения сохранности ваших ценностей предоставляет отдельные ячейки в «Хранилище», оснащенной новейшими техническим оборудованием и системами безопасности. Депозитарное Хранилище О.P. ”Universal Security Group” находится по адресу: мун. Кишинэу, ул. Мирон Костин, 19/1 A.\n" +
                "Тел: 079969874, 068301062.\n",
            mainInfo:"Индивидуальные сейфы в максимально безопасном месте – решение для жителей Молдовы, которые хотят обезопасить свое имущество!\n",
            addInfo:"\nХранить ценные вещи дома – это риск. Ведь кто из нас не повреждал важный документ (завещание, свидетельство о праве собственности, титул держателя акций и т. д.) или не терял в доме ценное кольцо или коллекционную монету? Чтобы сохранить такие ценности в безопасности, люди во всем мире прибегают к аренде ячеек в специализированных местах, в условиях максимальной безопасности.\n" +
                "\nВ Республике Молдова данный вид услуги предоставляют некоторые банки, но, несмотря на наличие запросов, они не хотят расширять этот вид услуги. Причина проста - банки поняли, что не могут обеспечить максимальную безопасность объектов, доказательством чему является ограбление 2019 года, когда 80 вкладчиков остались без своего имущества в сейфах банка. Тем не менее, во всем мире крайне востребованным решением являются индивидуальные сейфы в любом другом месте, кроме дома. В этих условиях молдавская компания открыла первый объект с индивидуальными сейфами, которые могут быть арендованы теми, кто хочет обезопасить свои материальные ценности.\n" +
                "\nКак охраняется объект?\n" +
                "\nНа хранение могут быть переданы документы, ценные бумаги, ценные вещи, ценности без определенной стоимости, а также любые другие вещи. Провайдер не ведет учет товаров, депонированных бенефициаром в индивидуальный сейф, и гарантирует неприкосновенность индивидуального сейфа путем исключения доступа к нему лиц, кроме бенефициара или уполномоченных им лиц. Содержимое индивидуальных ячеек известно только бенефициару.\n" +
                "\nСтраховка на 10 миллионов леев.\n" +
                "\nА чтобы ваши ценности находились в самом безопасном месте в Кишиневе, хранилище  было  застраховано  на сумму 10 000 000 леев. Таким образом USG берет на себя ответственность перед бенефициарами за неисполнение или ненадлежащее исполнение договорных обязательств.\n" +
                "\nНикаких рисков! Гарантируем!\n" +
                "\nХраните материальные ценности в самом безопасном месте в Кишиневе!\n"
        },
        alarm: {
            otherServices: "\nДругие услуги:\n",
            title: "Проектирование, установка и обслуживание охранной сигнализации",
            description1: " О.P. \"Universal Security Group\" SRL предлагает услуги по проектированию, установке и обслуживанию систем охранной сигнализации.\n" +
                "Проектирование системы безопасности предполагает создание сложной конфигурации оборудования: ",
            description2: "систем сигнализации, средств обнаружения, видеокамер, цифровой регистрации, системы контроля доступа, а также принятие мер по охране объектов, товаров и ценностей предусмотренных законом, перенесенных в план безопасности и проекты механических и электронных систем безопасности.\n" +
                "Свяжитесь с нами, и наши специалисты по проектированию, установке и обслуживанию систем сигнализации предложат вам оптимальные решения\n" +
                "\n",
            mainInfo:" О.P. \"Universal Security Group\" SRL предлагает услуги по проектированию, установке и обслуживанию систем охранной сигнализации.\n" +
                "Проектирование системы безопасности предполагает создание сложной конфигурации оборудования: систем сигнализации, средств обнаружения, видеокамер, цифровой регистрации, системы контроля доступа...",
            addInfo:"а также принятие мер по охране объектов, товаров и ценностей предусмотренных законом, перенесенных в план безопасности и проекты механических и электронных систем безопасности.\n" +
                "Свяжитесь с нами, и наши специалисты по проектированию, установке и обслуживанию систем сигнализации предложат вам оптимальные решения.\n" +
                "\n"
        },
        pazaFizica: {
            title: "Физическая охрана",
            description1: "О.P. ”Universal Security Group” SRL предлагает экономическим агентам и частным лицам услуги физической охраны по самым высоким стандартам на рынке.",
            description2: "Услуги физической охраны, предоставляемые нашей Компанией, адаптированы к каждому сегменту деятельности и каждому Клиенту. Оказание услуг физической охраны производственных, торговых, офисных, досуговых объектов, жилых комплексов, учреждений образования и культуры, домов и квартир осуществляется круглосуточно, либо в течение суток по заранее установленному графику.",
            mainInfo: "О.P. ”Universal Security Group” SRL предлагает экономическим агентам и частным лицам услуги физической охраны по самым высоким стандартам на рынке. Услуги физической охраны, предоставляемые нашей Компанией, адаптированы к каждому сегменту деятельности и каждому Клиенту. Оказание услуг физической охраны производственных, торговых, офисных, досуговых объектов, жилых комплексов, учреждений образования и культуры, домов и квартир осуществляется круглосуточно, либо в течение суток по заранее установленному графику."
        },
        transport: {
            title: "Транспорт, охрана и сопровождение",
            description1: "     Услуги, предоставляемые O.P. «Universal Security Group» SRL по перевозке, охране и сопровождению важных грузов/ценностей, личных вещей, а также обеспечению их сохранности, представляют собой услугу, которую мы предоставляем нашим клиентам, соблюдая положения законодательства.",
            description2: "Сопровождающий персонал состоит из агентов, обученных такой деятельности, подготовленных для выполнения современных методов обеспечения безопасности при сопровождении важных грузов/ценностей, защитных методов вождения и предотвращения дорожно-транспортных происшествий.\n" +
                "Таким образом,О.P.«Universal Security Group» полностью обеспечивает весь портфель услуг по перевозке, охране и сопровождению важных грузов/ценностей по всей стране.\n",
            mainInfo:"     Услуги, предоставляемые O.P. «Universal Security Group» SRL по перевозке, охране и сопровождению важных грузов/ценностей, личных вещей, а также обеспечению их сохранности, представляют собой услугу, которую мы предоставляем нашим клиентам, соблюдая положения законодательства.",
            addInfo: "Сопровождающий персонал состоит из агентов, обученных такой деятельности, подготовленных для выполнения современных методов обеспечения безопасности при сопровождении важных грузов / ценностей, защитных методов вождения и предотвращения дорожно-транспортных происшествий.\n" +
                "Таким образом, О.P. «Universal Security Group» SRL полностью обеспечивает весь портфель услуг по перевозке, охране и сопровождению важных грузов/ценностей по всей стране.\n"
        },
        corp:{
            title:"Служба персональной охраны",
            description1: "  O.P. \"Universal Security Group\" SRL предоставляет услуги персональной охраны по самым высоким стандартам. Услуги, предлагаемые нашей Компанией, обеспечивают необходимую вам безопасность и защиту. При предоставлении услуг персональной охраны наилучшие меры защиты принимаются только после проведения исследования рисков,",
            description2: " которым подвергаются защищаемые лица, исследования, проводимого специалистами в данной области в нашей Компании. Персонал, занятый в Службе персональной охраны, имеет возраст от 20 до 50 лет, они являются профессионалами этой сферы деятельности, имеют опыт работы в сфере охраны и безопасности государства.",
            mainInfo: "O.P. \"Universal Security Group\" SRL предоставляет услуги персональной охраны по самым высоким стандартам. Услуги, предлагаемые нашей Компанией, обеспечивают необходимую вам безопасность и защиту. При предоставлении услуг персональной охраны наилучшие меры защиты принимаются только после проведения исследования рисков, которым подвергаются защищаемые лица, исследования, проводимого специалистами в данной области в нашей Компании. ",
            addInfo:"Персонал, занятый в Службе персональной охраны, имеет возраст от 20 до 50 лет, они являются профессионалами этой сферы деятельности, имеют опыт работы в сфере охраны и безопасности государства, обладают высокими способностями и интеллектуальными и моральными качествами, навыками и практическим восприятием при обращении и применении огнестрельного оружия и физической силы.Сотрудники  Службы персональной охраны нашей Компании постоянно повышают своё профессиональное мастерство, периодически проходят тестирование, участвуя в различных упражнениях, приближенным к реальным ситуациям.\n" +
                "        Обратившись к нам, вы откроете для себя команду профессионалов, которые в любое время предложат вам самое надежное решение проблем, связанных с вашей безопасностью и безопасностью членов вашей семьи.\n"
        }
    },
    seeMore:"Смотреть больше",
    seeLess: "Смотреть меньше",
    navBar:{
        home:"Главная",
        service: "Службы",
        about:"О Нас",
        contact:"Контакты"
    },
    contactTitle:"Контакты"
};
