import React from "react";
import classes from "./About.module.css"
import useTranslation from "../../customHooks/translations";

function About() {
    const translation = useTranslation();

    return (

        <div className={classes.main}>
            <h1 className="page-title">{translation.aboutTitle}</h1>
            <div className={classes.aboutInfo}>

                <div className={classes.aboutInfoDescription}>
                    <p className={classes.description1}>
                      {translation.aboutText1}
                    </p>
                    <p className={classes.description1}>
                      {translation.aboutText2}
                    </p>
                </div>
            </div>
            <div className={classes.footerBlock}>
                <img src="image/home/logo.svg" alt="logo" className={classes.logo}/>
                <p className={classes.copyRight}>@UNIVERSAL SECURITY GROUP</p>
            </div>
        </div>
    );
}

export default About;
