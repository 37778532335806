import React, {useEffect} from "react";
import "./Footer.css"
import {useLocation} from "react-router-dom";
import ReactGa from "react-ga";
import * as ReactGA from "react-ga";
import LanguageHandler from "../../LanguageHandler";

function Footer() {
    const loc = useLocation();
    useEffect(() => {
        ReactGa.initialize('G-BN22K24W83');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (

        <div
            className={loc.pathname === "/service" ? "footerSercive" : loc.pathname === "/about" ? "footerAbout" : "footer"}>
            <a href="https://www.facebook.com/usgsrl/" className="fb social">
                <img src="image/home/facebook.svg" alt="facebook"/>
            </a>
            <div className="langChangeDesktop">
            <LanguageHandler />
            </div>
            <a href="mailto:office@usgsrl.md"
               className={loc.pathname === "/service" ? "emailService social" : loc.pathname === "/about" ? "emailAbout social" : "email social"}>
                <img src="image/home/email.svg" alt="email"/>
            </a>
            <div className="langChangeMobile">
                <LanguageHandler />
            </div>

        </div>
    );
}

export default Footer;
