import React from "react";
import classes from "./Home.module.css"
import useTranslation from "../../customHooks/translations";
import {NavLink} from "react-router-dom";

function Home(props) {
    const translation = useTranslation();

    return (
        <>
            <div className={classes.home}>
                <div>
                    <h1 className={classes.text}>UNIVERSAL SECURITY GROUP</h1>
                    <div className={classes.mainSevice}>
                        <img src="image/home/arrow.svg" alt="arrow nav" className={classes.homeArrow}/>
                        <NavLink className={classes.service} to="/service"> {translation.serviceTitle}</NavLink>
                    </div>

                </div>
                <div className={classes.logoWrap}>
                    <img src="image/home/logo.svg" alt="logo" className={classes.homeLogo}/>
                </div>
                <div className={classes.mainSeviceMobile}>
                    <div  className={classes.serviceMobileInfo}>
                        <NavLink to="/service"> {translation.serviceTitle}</NavLink>
                    </div>
                </div>
            </div>
            <div className={classes.usgCopyRight}>
                <p className={classes.copyRight}>@UNIVERSAL SECURITY GROUP</p>
            </div>
        </>
    );
}

export default Home;
